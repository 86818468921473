import React, { FC, ReactNode } from 'react';

import Layout from 'components/layout';

interface AppProps {
  children: ReactNode;
}

const App: FC<AppProps> = ({ children }) => <Layout>{children}</Layout>;

export default App;
