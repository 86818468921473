import client1Img from 'images/clients/1.webp';
import client2Img from 'images/clients/2.webp';
import client3Img from 'images/clients/3.webp';
import client4Img from 'images/clients/4.webp';
import client5Img from 'images/clients/5.webp';
import client6Img from 'images/clients/6.webp';
import client7Img from 'images/clients/7.webp';
import client8Img from 'images/clients/8.webp';
import client9Img from 'images/clients/9.webp';
import client10Img from 'images/clients/10.webp';
import client11Img from 'images/clients/11.webp';
import client12Img from 'images/clients/12.webp';
import client13Img from 'images/clients/13.webp';
import client14Img from 'images/clients/14.webp';
import client15Img from 'images/clients/15.webp';
import client16Img from 'images/clients/16.webp';
import client17Img from 'images/clients/17.webp';
import client18Img from 'images/clients/18.webp';
import client19Img from 'images/clients/19.webp';
import client20Img from 'images/clients/20.webp';
import client21Img from 'images/clients/21.webp';
import client22Img from 'images/clients/22.webp';
import client23Img from 'images/clients/23.webp';
import client24Img from 'images/clients/24.webp';
import client25Img from 'images/clients/25.webp';
import client26Img from 'images/clients/26.webp';
import client27Img from 'images/clients/27.webp';
import client28Img from 'images/clients/28.webp';
import client29Img from 'images/clients/29.webp';
import client30Img from 'images/clients/30.webp';
import client31Img from 'images/clients/31.webp';

import story1Img from 'images/about/story/1.webp';
import story2Img from 'images/about/story/2.webp';
import story3Img from 'images/about/story/3.webp';
import story4Img from 'images/about/story/4.webp';
import story5Img from 'images/about/story/5.webp';
import story6Img from 'images/about/story/6.webp';
import story7Img from 'images/about/story/7.webp';
import story8Img from 'images/about/story/8.webp';
import story9Img from 'images/about/story/9.webp';
import story10Img from 'images/about/story/10.webp';
import story11Img from 'images/about/story/11.webp';
import story12Img from 'images/about/story/12.webp';
import story13Img from 'images/about/story/13.webp';
import story14Img from 'images/about/story/14.webp';
import story15Img from 'images/about/story/15.webp';

export const navigationLinks = [
  {
    text: 'home',
    href: '/',
  },
  {
    text: 'about',
    href: '/about',
  },
  {
    text: 'services',
    href: '/services',
    partiallyActive: true,
  },
  {
    text: 'contact us',
    href: '/contact',
  },
];

export const features = [
  {
    icon: 'CalendarCheck',
    text: '58+ years of experience',
  },
  {
    icon: 'UserCircle',
    text: 'Customer centric approach',
  },
  {
    icon: 'TrendingUp',
    text: '20,000+ clients',
  },
];

export const clients = [
  client1Img,
  client2Img,
  client3Img,
  client4Img,
  client5Img,
  client6Img,
  client7Img,
  client8Img,
  client9Img,
  client10Img,
  client11Img,
  client12Img,
  client13Img,
  client14Img,
  client15Img,
  client16Img,
  client17Img,
  client18Img,
  client19Img,
  client20Img,
  client21Img,
  client22Img,
  client23Img,
  client24Img,
  client25Img,
  client26Img,
  client27Img,
  client28Img,
  client29Img,
  client30Img,
  client31Img,
];

export const contacts = [
  {
    icon: 'Phone',
    text: '+916352115491',
    link: 'tel:+916352115491',
  },
  {
    icon: 'Mail',
    text: 'dishasolutions.baroda@gmail.com',
    link: 'mailto:dishasolutions.baroda@gmail.com',
  },
  {
    icon: 'MapPin',
    text: 'Makarpura, Vadodara',
    link: 'https://www.google.com/maps/dir//370+b,+GIDC+Rd,+opp.+MG+Motor,+Makarpura+GIDC,+Makarpura,+Vadodara,+Gujarat+390010/@22.253932,73.1024734,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395fc5d5272bd1e1:0xeffe9fcabfbf62ed!2m2!1d73.1847874!2d22.2538857?entry=ttu',
  },
];

export const socials = [
  {
    icon: 'Facebook',
    text: '@disha.concepts',
    link: 'https://www.facebook.com/people/Disha-Concepts-Pvt-Ltd/61557543657970/',
  },
  {
    icon: 'Instagram',
    text: '@dishaconcepts',
    link: 'https://www.instagram.com/dishaconcepts/',
  },
  {
    icon: 'Linkedin',
    text: '@disha.concepts',
    link: 'https://instagram.com',
  },
  {
    icon: 'Twitter',
    text: '@disha.concepts',
    link: 'https://instagram.com',
  },
];

export const importantStatements = [
  {
    title: 'our mission',
    text: "At the heart of Disha Concepts' operations is an unwavering commitment to excellence. The company's mission is to continually refine its craft and expand its capabilities, harnessing the latest advancements in technology not just to keep up with the times, but to redefine them. Disha Concepts' goal is to deliver products and services of unmatched quality, ensuring creativity, innovation, and above all, client satisfaction. The company doesn't just meet expectations; it aims to exceed them, every single time.",
  },
  {
    title: 'our vision',
    text: "Disha Concepts' vision extends beyond the borders of India, as the company has already initiated exports and aims to become a beacon of trust and excellence in the industry globally. The company's plans include expanding its reach across India and diversifying its services to ensure its name becomes synonymous with innovation and excellence. At Disha Concepts, the focus is not just on building products, but on creating experiences and legacies.",
  },
  {
    title: 'our commitment',
    text: "At Disha Concepts, customer satisfaction is our top priority. We are committed to delivering high-quality products and services that exceed expectations. Our team of skilled professionals is dedicated to embracing new ideas, creativity, and challenges to ensure that every project is a success. Whether you're looking for signage solutions, printing services, or interior décor, we are here to turn your vision into reality.",
  },
];

export const neonColors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A6'];

export const storyImages = [
  story1Img,
  story2Img,
  story3Img,
  story4Img,
  story5Img,
  story6Img,
  story7Img,
  story8Img,
  story9Img,
  story10Img,
  story11Img,
  story12Img,
  story13Img,
  story14Img,
  story15Img,
];
